@import "../../styles/core/base";

.notifications {
   display: flex;
   flex-direction: column-reverse;
   position: absolute;
   list-style: none;
   top: 0;
   left: 0;
   width: 100%;
   z-index: 100;

   &__item {
      margin-bottom: 2rem;
      transition: all 0.2s ease-out 0s;
      padding: 15px;
      color: #fff;
      font-weight: 500;
      font-size: 1.4rem;
      border-radius: 3px 3px 0 0;
      text-align: center;

      &_success {
         background-color: $color-positive !important;
      }
      &_error {
         background-color: $color-red;
      }
      svg {
         margin-right: 7px;
      }
   }

   &__close {
      position: absolute !important;
      top: 0;
      right: 0;
      color: #fff;
      margin: 0 !important;
      width: 10px;
      height: 10px;
      i {
         font-size: 1.8rem !important;
      }
      svg {
         color: rgb(207, 204, 204);
         width: 10px;
         height: 10px;
      }
      &:hover {
         opacity: 1;
         font-size: 1.8rem;
      }
   }
}
