@import "../../styles/core/base";
.onBoarding {
   width: 520px;
   padding: 35px 25px;
   background: $color-white;
   box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.05);
   border-radius: 6px;
   h3 {
      color: $color-black;
      margin-bottom: 10px;
   }
   &__header {
      margin-bottom: 25px;
   }
   &__info,
   &__welcome {
      @include flex(center, center, column);
      h3 {
         font-size: 2rem;
         color: $color-black;
         margin-bottom: 20px;
      }

      button {
         margin-top: 40px;
      }
   }
   &__welcome {
      img {
         margin-bottom: 40px;
         width: 60px;
      }
   }
   &__info {
      p {
         text-align: center;
         max-width: 350px;
      }
      svg {
         margin-bottom: 20px;
         width: 60px;
      }
   }
}

.userForm {
   padding: 25px;
   h3 {
      font-size: 2rem;
      color: $color-black;
      margin-bottom: 30px;
   }
   &__description {
      margin: 15px 0;
      ul {
         list-style: initial;
         margin-left: 30px;
      }
   }
   &__btns {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 10px;
      button {
         flex: 1 1 0;
         min-width: 30% !important;
         margin: 10px 0;
         svg {
            margin-right: 10px;
         }
      }
   }
   &__twoCols {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 10px;
   }
}
