$screen-xxs: 480px;
$screen-xs: 680px;
$screen-sm: 768px;
$screen-smd: 820px;
$screen-md: 1024px;
$screen-lg: 1200px;
$screen-xl: 1366px;
$screen-xlg: 1440px;
$screen-xxl: 2560px;

$color-main: #057eee;
$color-positive: #1abc9c;
$color-warning: #b30949;
$color-sidebar: #0d2033;
$color-sidebar-labels: #728497;
$color-sidebar-login: #1f3346;
$color-background: #f3f6f8;
$color-link: #057eee;

$color-white: #ffffff;
$color-black: #000000;
$color-grey: #8b9099;
$color-light-grey: #ebebeb;
$color-red: #e74c3c;
$color-dark-blue: #0a1e40;
$color-dark-green: #1e9f86;

$color-chart-green: #06d6a0;
$color-chart-blue: #118ab2;

$margin-s: 8px;
$margin-m: 16px;
$margin-l: 24px;
$margin-xl: 32px;
