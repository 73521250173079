@import "../../styles/core/base";

.usersInfo {
   @include flex(normal, space-between);
   &__inner {
      margin-right: 20px;
      font-size: 13px;
      span {
         font-weight: 600;
      }
   }
}
