@import "../../styles/core/base";
@import "../../styles/mixins/mobile";

.sidebar {
   position: fixed;
   top: 0;
   left: 0;
   bottom: 0;
   width: 250px;
   height: 100%;
   max-height: 100%;
   z-index: 100;
   color: #fff;
   background: $color-sidebar;
   transition: left 0.1s ease-in;

   &__sticky {
      @include flex(normal, space-between, column);
      height: 100%;
      position: relative;
   }
   &__inner {
      padding: 32px 16px;
   }
   &__logo {
      width: 48px;
      display: block;
      margin: 0 auto;
   }

   &__nav {
      @include flex(normal, flex-start);
      flex-direction: column;
      margin-top: 70px;
      &_activeLink {
         background: $color-main;
         color: $color-white !important;
         svg {
            path {
               fill: $color-white;
            }
         }
      }

      &_link {
         @include flex(center, flex-start);
         font-size: 1.5rem;
         color: $color-sidebar-labels;
         padding: 15px 18px;
         @include laptop-m-h {
            padding: 10px 18px;
            font-size: 1.3rem;
         }
         line-height: 1.5;
         margin-bottom: 5px;
         border: 1px solid transparent;
         border-radius: 4px;
         position: relative;
         transition: all 0.5s;
         svg {
            margin-right: 10px;
            path {
               transition: all 0.5s;
            }
         }

         &:hover {
            border-color: $color-main;
            color: $color-main !important;
            background: none;
            svg {
               path {
                  fill: $color-main;
               }
            }
         }
         &_disabled {
            cursor: default;
            &:hover {
               border-color: $color-grey;
               color: $color-grey !important;
               background: none;
               svg {
                  path {
                     fill: $color-grey;
                  }
               }
            }
         }
      }
   }
   &__user {
      @include flex(center, space-between);
      border-top: 1px solid rgba(255, 255, 255, 0.1);
      padding: 25px 16px;
      cursor: pointer;
      transition: all 0.5s;
      &_left {
         @include flex(center, flex-start);
         margin-right: 10px;
      }
      &_menu {
         position: absolute;
         top: 0;
         left: 100%;
         width: 0;
         height: 100%;
         background: $color-sidebar-login;
         overflow-x: hidden;
         transition: 0.5s;
         z-index: 100;
         &_open {
            width: 250px;
         }
         &_inner {
            padding: 30px 25px;
            width: 250px;
         }
         h3 {
            font-size: 1.8rem;
            color: $color-white;
            margin-bottom: 35px;
         }
         p {
            font-size: 1.5rem;
            margin-bottom: 20px;
         }
      }
      &_link,
      &_btn {
         @include flex(center, flex-start);
         display: flex !important;
         align-items: center !important;
         justify-content: flex-start !important;
         flex-direction: row !important;
         font-size: 1.5rem !important;
         margin-bottom: 20px;
         padding: 0 !important;
         color: $color-white !important;
         &:hover {
            color: $color-main !important;
            svg {
               path {
                  fill: $color-main !important;
               }
            }
         }
         > div {
            width: 25px;
            margin-right: 10px;
         }
      }
      svg {
         margin-right: 10px;
         path {
            transition: all 0.5s;
         }
      }
      p {
         font-size: 1rem;
         font-weight: bold;
         color: $color-white;
         transition: all 0.5s;
      }
      h4 {
         font-size: 1.6rem;
         font-weight: normal;
         color: $color-white;
         transition: all 0.5s;
      }
      &:hover {
         p,
         h4 {
            color: $color-main;
         }
         svg {
            path {
               fill: $color-main;
            }
         }
      }
   }
}

.modalForm {
   padding: 25px;
   position: relative;
   h3 {
      font-size: 2rem;
      color: $color-black;
      margin-bottom: 30px;
   }
   &__description {
      margin: 15px 0;
      ul {
         list-style: initial;
         margin-left: 30px;
      }
   }
   &__btns {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 10px;
      button {
         flex: 1 1 0;
         min-width: 30% !important;
         margin: 10px 0;
         svg {
            margin-right: 10px;
         }
      }
   }
   &__twoCols {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 10px;
   }
   &__error {
      color: $color-red;
      font-size: 1.3rem;
   }
}
