@import "../../styles/core/base";

.searchBar {
   position: relative;
   &__searchIcon {
      position: absolute;
      top: 50%;
      left: 12px;
      margin-top: -10px;
      width: 20px;
      height: 20px;
   }
   input {
      padding: 11px 15px 11px 45px !important;
      min-width: 265px;
      font-size: 14px;
      border-radius: 4px;
      color: #a3a3a3;
      &:hover {
         border-color: $color-main !important;
         box-shadow: none !important;
      }
   }
   &__reset {
      position: absolute;
      top: 50%;
      right: 12px;
      margin-top: -10px;
      width: 16px;
      height: 16px;
      padding: 0 !important;
      &:hover {
         svg {
            path {
               fill: #95a5a6 !important;
            }
         }
      }
   }
}
