@import "../../styles/core/base";

.pagination {
   &__container {
      @include flex(center, center);
      list-style-type: none;
      margin-top: 25px;
      margin-bottom: 15px;
   }

   &__item {
      @include flex(center, center);
      width: 40px;
      height: 32px;
      min-width: 40px;
      padding: 3px;
      margin: auto 4px;
      color: rgba(0, 0, 0, 0.87);
      border-radius: 4px;
      font-size: 1.3rem;
      background-color: $color-white;
      border: 1px solid transparent;

      &_dots:hover {
         background-color: transparent;
         cursor: default;
      }
      &:hover {
         border-color: $color-main;
         cursor: pointer;
      }

      &_selected {
         border-color: $color-main;
      }

      &_arrow {
         background-color: $color-main;
         svg {
            path {
               fill: $color-white;
            }
         }
      }

      &_disabledArrow {
         pointer-events: none;
         background-color: $color-white;
         svg {
            path {
               fill: $color-grey;
            }
         }
         &:hover {
            background-color: transparent;
            cursor: default;
         }
      }
   }
}
