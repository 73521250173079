@import "../../styles/core/base";

.header {
   @include flex(normal, space-between);
   background: $color-white;
   margin-bottom: 15px;
   position: fixed;
   width: calc(100% - 250px);
   right: 0;
   top: 0;
   z-index: 10;
   box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.07);

   &__btnGoBack {
      @include flex(center, center);
      width: 80px;
      border-right: 1px solid #dadada;
      button {
         width: 40px;
         height: 40px;
         transition: all 0.2s ease-out 0s !important;
         svg {
            transition: all 0.2s ease-out 0s !important;
            path {
               transition: all 0.2s ease-out 0s !important;
            }
         }
         &:hover {
            background: $color-main;
            svg {
               path {
                  fill: $color-white;
               }
            }
         }
      }
   }
   &__right {
      @include flex(center, space-between);
      padding: 13px 25px;
      width: 100%;
      h3 {
         font-size: 1.8rem;
         color: $color-black;
      }
   }
   &__info {
      @include flex(center, space-between);
      p {
         margin: 5px 10px;
         font-weight: 600;
         span {
            font-weight: normal;
         }
      }
      &_status {
         width: 10px;
         height: 10px;
         border-radius: 50%;
         display: inline-block;
         &_2 {
            background-color: $color-grey;
         }
         &_1 {
            background-color: $color-positive;
         }
         &_0 {
            background-color: $color-red;
         }
      }
      button {
         svg {
            margin-right: 7px;
            vertical-align: text-bottom;
         }
      }
   }
   &__space {
      height: 60px;
   }
}
