@import "../../styles/core/base";
@import "../../styles/core/fonts";

.sidebar {
   @include flex(center, space-between, column);
   width: 405px;
   min-width: 405px;
   height: 100vh;
   max-height: 100vh;
   background: $color-white;
   transition: left 0.1s ease-in;

   &__form {
      width: 100%;
      padding: 30px;
      h2 {
         font-family: "ScaniaSansHeadline";
         color: $color-dark-blue;
         text-transform: uppercase;
         font-size: 2.8rem;
         margin-bottom: 25px;
      }
      form {
         width: 100%;
         button {
            width: 100%;
            margin: 10px 0 !important;
         }
      }
      &_error {
         text-align: right;
         color: #dc3545;
         font-size: 1.2rem;
      }
   }
   &__logo {
      padding: 30px;

      img {
         width: 60px;
         display: block;
         margin: 0 auto;
      }
   }
   &__tip {
      width: 100%;
      position: relative;
      padding: 30px 30px 30px 45px;
      border-top: 1px solid $color-light-grey;
      &::before {
         content: "";
         display: block;
         position: absolute;
         top: 30px;
         left: 12px;
         background-repeat: no-repeat;
         background-position: center;
         background-size: contain;
         width: 20px;
         height: 20px;
         background-image: url("../../assets/images/tipIcon.svg");
      }
   }
}
.input {
   position: relative;
   input {
      padding-left: 40px;
   }
   &__name,
   &__password {
      &::before {
         content: "";
         display: block;
         position: absolute;
         top: 10px;
         left: 12px;
         background-repeat: no-repeat;
         background-position: center;
         background-size: contain;
         width: 20px;
         height: 20px;
      }
   }
   &__name {
      &:before {
         background-image: url("../../assets/images/userIcon.svg");
      }
   }
   &__password {
      &:before {
         background-image: url("../../assets/images/passwordIcon.svg");
      }
   }
}
