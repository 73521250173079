@import "../../styles/core/base";

.main {
   display: flex;
   width: 100%;
   //height: 100%;
   padding: 0;
   margin: 0;
   overflow-x: hidden;
   &__panel {
      min-height: 100vh;
      width: 100%;
      height: 100%;
   }
}
