@import "../../styles/core/base";
@import "../../styles/modules/form";

.userInfo {
   @include flex(normal, space-between);
   flex-wrap: wrap;
   width: 100%;
   padding: 0 25px 25px 25px;
   &__item {
      width: 50%;
      margin-top: 25px;
      h3 {
         font-size: 1.3rem;
         color: $color-grey;
         margin-bottom: 15px;
      }
   }
}
.data {
   &__list {
      width: 100%;
      table {
         background: $color-white;
         box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.05);
         border-radius: 6px;
         font-size: 1.3rem;
         margin-bottom: 0;
         thead {
            border: none;
            box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.05);
            th {
               border: none;
            }
         }

         td,
         th {
            padding: 2.5rem;
         }
         tbody {
            tr {
               transition: all 0.5s;
               &:hover {
                  background: rgba(5, 126, 238, 0.15);
                  cursor: pointer;
               }
               &:last-child {
                  td {
                     border: none;
                  }
               }
            }
         }
      }
   }
}
.segmentation {
   &__tabs {
      width: 100%;

      ul {
         width: 100%;
         padding: 0 15px;
         li {
            button {
               border: none !important;
            }
            & [class*="active"] {
               color: $color-black !important;
               &:after {
                  content: "";
                  display: block;
                  position: absolute;
                  bottom: 0;
                  left: 0;
                  width: 100%;
                  height: 3px;
                  background: $color-main;
               }
            }
         }
      }
      &_nav {
         font-size: 1.6rem;
         font-weight: 600;
         color: $color-grey !important;
         padding: 15px 0 !important;
         margin-right: 35px !important;
         border: none !important;
         background: none !important;
         position: relative;
         transition: all 0.5s;
         &:hover {
            color: $color-black !important;
         }
      }
   }
   &__btns {
      @include flex(center, flex-end);
      width: 100%;
      border-top: 1px solid $color-light-grey;
      padding-top: 25px;
   }
}
.tabInner {
   &__item {
      @include flex(normal, space-between);
      border-bottom: 1px solid $color-light-grey;
      padding: 15px;
      &:last-child {
         border: none;
      }

      &_title {
         font-size: 1.4rem;
         font-weight: 600;
         color: $color-black;
         width: 12%;
         min-width: 110px;
         margin-right: 20px;
         p {
            margin: 15px 0;
         }
      }
      &_list {
         @include flex(center, flex-start);
         flex-wrap: wrap;
         width: 100%;
         font-size: 1.4rem;
      }
      &_checkbox {
         margin: 15px 15px;
         input {
            margin-right: 10px;
         }
      }
   }
}
