@import "../../styles/core/base";
@import "../../styles/modules/form";

.modal {
   padding: 25px;
   &__header {
      h3 {
         font-size: 2rem;
         font-weight: 600;
         color: $color-black;
         margin-bottom: 10px;
      }
      p {
         line-height: 1.4;
      }
   }
   &__footer {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 10px;
      button {
         flex: 1 1 0;
         min-width: 30% !important;
         margin: 10px 0;
      }
   }
   &__body {
      margin: 20px 0;
   }
}
.modalInvite {
   @include flex(center, center, column);
   padding: 35px 25px;
   &__header {
      @include flex(center, center, column);
      margin-top: 25px;
      h3 {
         font-size: 2rem;
         font-weight: 600;
         color: $color-black;
         margin-top: 10px;
      }
      p {
         line-height: 1.4;
      }
      svg {
         width: 64px;
         height: 64px;
      }
   }
   &__footer {
      @include flex(center, center);
   }
   &__body {
      margin: 20px 0;
   }
}
