@import "../../styles/core/base";

.main {
   @include flex(center, center);
   width: 100%;
   height: 100%;
   min-height: 100vh;
   padding: 0;
   margin: 0;
   overflow-x: hidden;

   position: relative;
   &__background {
      object-fit: cover;
      width: 100vw;
      height: 100vh;
      position: absolute;
      top: 0;
      left: 0;
      z-index: -1;
   }
}
