@import "../../styles/core/base";
@import "../../styles/modules/form";

.items {
   &__list {
      width: 100%;
      table {
         background: $color-white;
         box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.05);
         border-radius: 6px;
         font-size: 1.3rem;
         margin-bottom: 0;
         thead {
            border: none;
            box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.05);
            th {
               border: none;
            }
         }

         td,
         th {
            padding: 2.5rem;
         }
         tbody {
            tr {
               transition: all 0.5s;
               &:hover {
                  background: rgba(5, 126, 238, 0.15);
                  cursor: pointer;
               }
               &:last-child {
                  td {
                     border: none;
                  }
               }
            }
         }
      }
      &_mb {
         padding-bottom: 20px;
         table {
            box-shadow: none;
         }
      }
   }

   &__status {
      width: 11px;
      height: 11px;
      border-radius: 50%;
      display: inline-block;
      margin-right: 5px;
      &_1 {
         background: $color-positive;
      }
      &_2 {
         background: $color-red;
      }
      &_0 {
         background: $color-grey;
      }
   }
}

.tooltip {
   position: relative;
   display: inline-block;
   &__text {
      visibility: hidden;
      background-color: #0d2033;
      color: #fff;
      text-align: center;
      padding: 5px 0;
      border-radius: 4px;
      width: 160px;
      margin-left: -80px;
      position: absolute;
      top: -40px;
      left: 50%;
      font-size: 1.3rem;
      z-index: 1;
      &::after {
         content: "";
         display: block;
         position: absolute;
         bottom: -5px;
         left: 50%;
         margin-left: -5px;
         width: 0;
         height: 0;
         border-style: solid;
         border-width: 5px 5px 0 5px;
         border-color: #0d2033 transparent transparent transparent;
      }
   }

   &:hover {
      & [class*="tooltip__text"] {
         visibility: visible;
      }
   }
}
