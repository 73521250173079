html,
body,
div,
span,
iframe,
a,
h1,
h2,
h3,
h4,
h5,
h6,
p,
pre,
code,
em,
img,
small,
strong,
b,
u,
i,
ol,
ul,
li,
fieldset,
form,
label,
article,
aside,
figure,
nav,
figcaption,
footer,
header,
hgroup,
menu,
section,
mark,
audio,
video {
   border: 0;
   margin: 0;
   padding: 0;
   vertical-align: baseline;
}

html {
   font-size: 62.5%;
   -webkit-font-smoothing: subpixel-antialiased;
   -moz-osx-font-smoothing: grayscale;
   -webkit-text-size-adjust: none;
}

html,
body,
[id="root"] {
   height: 100%;
}

body {
   background-color: $color-background;
   color: $color-black;
   font-family: "Poppins", sans-serif;
   overflow-x: hidden;
   font-weight: 400;
}

input,
textarea,
button,
a,
:focus {
   outline: 0;
}

ol,
ul {
   list-style: none;
}

input,
textarea,
p,
li,
a {
   font-size: 1.4rem;
}

pre,
code {
   font-size: 1.2rem;
   overflow: hidden;
   text-overflow: ellipsis;
}

input,
textarea {
   font-weight: 400;
}

p,
li {
   line-height: 1.8;
}

a {
   text-decoration: none;
   color: $color-link;
}

iframe,
img {
   max-width: 100%;
   width: 116px;
}

*,
*:before,
*:after {
   box-sizing: border-box;
   outline: 0;
}

hr {
   border: 0;
   margin: 3rem 0;
   padding: 0;
   border-top: 0.1rem solid #ddd;
}

h1,
h2,
h3,
h4,
h5,
h6 {
   color: $color-grey;
   line-height: 1.4;
}

h1,
h2,
h3 {
   font-size: 2rem;
   font-weight: 600;
   @include mobile-lg {
      font-size: 1.8rem;
   }
}

h4,
h5,
h6 {
   font-size: 1.2rem;
}
/**********************rc-slider********/
.rc-slider {
   &-rail {
      height: 10px;
      background: #e3e3e3;
      border: 1px solid #d3d3d3;
      box-sizing: border-box;
      border-radius: 100px;
   }
   &-track {
      height: 10px;
      border-radius: 100px;
      background-color: $color-main;
   }
   &-handle {
      width: 28px;
      height: 28px;
      margin-top: -10px;
      border: solid 5px $color-white;
      background-color: $color-main;
      box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
      &:hover,
      &:focus {
         border-color: $color-white;
         box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
      }
   }

   &-tooltip {
      top: -36px !important;
      &-hidden {
         display: block;
      }
      &-inner {
         background-color: $color-white;
         border: 1px solid $color-light-grey;
         border-radius: 4px;
         font-size: 1.1rem;
         font-weight: 600;
         color: $color-black;
         box-shadow: none;
         padding: 4px;
         height: auto;
      }
      &-arrow {
         display: none;
      }
   }
}
.rc-slider-handle-dragging.rc-slider-handle-dragging.rc-slider-handle-dragging {
   border-color: $color-white;
   box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
}
/**************Btn styles**************/

.btn {
   &-primary,
   &-warning,
   &-success,
   &-outline-primary,
   &-outline-secondary {
      color: $color-white !important;
      font-size: 14px !important;
      font-weight: 500 !important;
      padding: 11px 20px !important;
      margin: 5px 10px;
      min-width: 226px;
      border-radius: 4px !important;
      text-transform: none !important;
      transition: all 0.2s ease-out 0s !important;
      box-shadow: none !important;

      &:disabled {
         background: #b5c4ce !important;
         border-color: #b5c4ce !important;
         opacity: 1 !important;
      }
   }
   &-primary {
      background: $color-main !important;
      border-color: $color-main !important;

      &:hover {
         background: linear-gradient(0deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.1)), $color-main !important;
         border-color: linear-gradient(0deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.1)), $color-main !important;
      }
      &:focus {
         background: linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), $color-main !important;
         border-color: linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), $color-main !important;
      }
   }
   &-warning {
      background: $color-warning !important;
      border-color: $color-warning !important;

      &:hover {
         background: linear-gradient(0deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.1)), $color-warning !important;
         border-color: linear-gradient(0deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.1)), $color-warning !important;
      }
      &:focus {
         background: linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), $color-warning !important;
         border-color: linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), $color-warning !important;
      }
   }
   &-success {
      background: $color-positive !important;
      border-color: $color-positive !important;

      &:hover {
         background: linear-gradient(0deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.1)), $color-positive !important;
         border-color: linear-gradient(0deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.1)), $color-positive !important;
      }
      &:focus {
         background: linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), $color-positive !important;
         border-color: linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), $color-positive !important;
      }
   }
   &-outline-primary {
      background: $color-white !important;
      border-color: $color-main !important;
      color: $color-main !important;
      &:hover {
         background: rgba(5, 126, 238, 0.05) !important;
      }
      &:focus {
         background: rgba(5, 126, 238, 0.12) !important;
      }
   }
   &-outline-secondary {
      background: none !important;
      border-color: $color-grey !important;
      color: $color-white !important;
      &:hover,
      &:focus {
         border-color: $color-main !important;
         color: $color-main !important;
      }
   }
   &-icon {
      border: none !important;
      box-shadow: none !important;
      &:focus {
         border: none;
      }
      &:hover,
      &_active {
         svg {
            path {
               fill: $color-black;
            }
         }
      }
   }
}

/******************Form styles*************/
.form {
   &-label {
      font-size: 1.4rem;
   }
   &-control,
   &-select {
      font-size: 1.4rem;
      padding: 10px 16px;
      border-radius: 4px;
      box-shadow: none;
      &:focus {
         box-shadow: none;
         border-color: 1px solid $color-main;
      }
   }
   &-check {
      &-input {
         &:disabled {
            &:checked {
               background-color: $color-grey;
               border-color: $color-grey;
            }
         }
      }
      &-p {
         label {
            font-size: 1.4rem;
            margin-left: 7px;
         }
      }
   }
}
.form-floating > .form-select {
   height: calc(3.5rem + 6px);
}
.invalid-feedback {
   font-size: 1.2rem;
}

.offcanvas-top {
   height: 100vh;
}
.offcanvas-end {
   width: 100%;
}
.modal-backdrop {
   z-index: 1060;
}
.modal {
   z-index: 1070;
}

/****************React calendar*************/
.react-datepicker__time-container {
   width: 119px;
   & .react-datepicker__time .react-datepicker__time-box {
      width: 119px;
   }
}
.react-datepicker__current-month,
.react-datepicker-time__header,
.react-datepicker-year-header {
   font-size: 1.2rem;
}
.react-datepicker {
   font-size: 1.2rem;
}
.react-datepicker__time-container
   .react-datepicker__time
   .react-datepicker__time-box
   ul.react-datepicker__time-list
   li.react-datepicker__time-list-item--selected:hover {
   background-color: $color-main;
}
.react-datepicker__time-container
   .react-datepicker__time
   .react-datepicker__time-box
   ul.react-datepicker__time-list
   li.react-datepicker__time-list-item--selected {
   background-color: $color-main;
}
.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::before {
   top: -1px;
   border-bottom-color: $color-main;
}
.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::after {
   border-top: none;
   border-bottom-color: #0d6efd;
}
.react-datepicker__header {
   text-align: center;
   background-color: $color-main;
   border-bottom: 1px solid $color-main;
}
.react-datepicker__current-month,
.react-datepicker-time__header,
.react-datepicker-year-header {
   color: #fff;
}

.react-datepicker {
   font-size: 1.2em;
}
.react-datepicker__header {
   padding-top: 0.8em;
}
.react-datepicker__month {
   margin: 0.4em 1em;
}
.react-datepicker__day-name,
.react-datepicker__day {
   width: 1.9em;
   line-height: 1.9em;
   margin: 0.166em;
}
.react-datepicker__current-month {
   font-size: 1.2em;
}
.react-datepicker__navigation {
   top: 0.5em;
   line-height: 1.7em;
   border: none;
}
.react-datepicker__navigation--previous {
   border-right-color: #ccc;
   left: 1em;
}
.react-datepicker__navigation--next {
   border-left-color: #ccc;
   right: 1em;
}
.react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow,
.react-datepicker__navigation-icon::before {
   border-color: #fff;
}
.react-datepicker__navigation:hover *::before {
   border-color: #dee2e6;
}
.react-datepicker__day-name {
   color: #dee2e6;
}
