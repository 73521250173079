@import "../../styles/core/base";
@import "../../styles/modules/form";

.accordionBlock {
   padding: 25px;
   background: #ffffff;
   box-shadow: 0 4px 14px rgba(0, 0, 0, 0.05);
   border-radius: 6px;
   margin-bottom: 20px;
   h3 {
      font-size: 2rem;
      font-weight: 600;
      color: $color-black;
   }
   &__header {
      > button {
         background: none !important;
         box-shadow: none !important;
         padding: 0 !important;
         &:after {
            width: 2.25rem;
            height: 2.25rem;
            background-size: 2.25rem;
         }
      }
   }
   &__body {
      padding: 0 !important;
      margin-top: 30px;
   }
}

.formCalendar {
   &__btns {
      @include flex(center, flex-end);
      button {
         min-width: 140px;
      }
   }
}

.publishCalendar {
   @include flex(center, flex-start);
   margin-bottom: 20px;
   &__inner {
      position: relative;
      &:first-child {
         margin-right: 35px;
      }
      &_error {
         position: absolute;
         bottom: -20px;
         left: 0;
         color: $color-red;
         font-size: 1.2rem;
      }
   }
   &__error {
      margin-top: 20px;
      color: $color-red;
      font-size: 1.2rem;
   }
}

.customInput {
   width: 150px;
   height: 43px;
   font-size: 1.4rem;
   text-align: left;
   padding: 10px 16px;
   border-radius: 4px;
   box-shadow: none;
   border: 1px solid #ced4da;
   background-color: #fff;
   font-weight: 400;
   line-height: 1.5;
   color: #212529;
   max-width: 100%;
   position: relative;
   transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
   &:focus {
      border-color: #86b7fe;
   }
   &_disable {
      background-color: #f0f0f0;
      &::after {
         opacity: 0.3;
      }
      &:focus {
         border-color: #ced4da;
      }
   }

   &::after {
      content: "";
      display: block;
      position: absolute;
      top: 50%;
      right: 10px;
      background: url("../../assets/images/arrowCalendar.svg") no-repeat;
      width: 10px;
      height: 6px;
      margin-top: -3px;
   }
}
.calendar {
   @include flex(center, flex-start);
   margin-top: 20px;
   h3 {
      font-size: 1.4rem;
      font-weight: 600;
      margin-bottom: 5px;
   }
   &__date {
      max-width: 150px;
      margin-right: 15px;
   }
   &__time {
      max-width: 125px;
   }
}
