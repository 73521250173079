@import "../../styles/core/base";
@import "../../styles/modules/form";

.createContent {
   @include flex(center, center, column);
   &__field {
      @include flex(center, center);
      width: 190px;
      height: 250px;
      margin: 15px auto;
      border: 1px dashed $color-grey;
      box-sizing: border-box;
      filter: drop-shadow(0px 4px 14px rgba(0, 0, 0, 0.15));
      border-radius: 4px;
   }
   button {
      min-width: 190px !important;
   }
}
.settings {
   &__form {
      width: 100%;
   }
   &__btns {
      @include flex(center, flex-end);
      margin-top: 20px;
      button {
         min-width: 140px !important;
         &:last-child {
            margin-right: 0 !important;
         }
      }
   }
}
.campaignsEdit {
   padding-bottom: 20px;
   &__row {
      @include flex(normal, space-between);
      margin-bottom: 15px;
   }
   &__left {
      width: 35%;
      min-width: 370px;
   }
   &__right {
      width: 100%;
      margin-left: 15px;
   }
}
.segmentation {
   &__tabs {
      width: 100%;
      min-height: 650px;
      ul {
         width: 100%;
         padding: 0 15px;
         li {
            button {
               border: none !important;
            }
         }
      }
      &_nav {
         font-size: 1.6rem;
         font-weight: 600;
         color: $color-grey !important;
         padding: 15px 0 !important;
         margin-right: 35px !important;
         border: none !important;
         background: none !important;
         position: relative;
         transition: all 0.5s;
         &:hover {
            color: $color-black !important;
         }
      }
      & [class*="active"] {
         color: $color-black !important;
         &:after {
            content: "";
            display: block;
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 3px;
            background: $color-main;
         }
      }
   }
   &__btns {
      @include flex(center, flex-end);
      width: 100%;
      border-top: 1px solid $color-light-grey;
      padding-top: 25px;
   }
}
.tabInner {
   &__item {
      @include flex(normal, space-between);
      border-bottom: 1px solid $color-light-grey;
      padding: 15px;
      &:last-child {
         border: none;
      }

      &_title {
         font-size: 1.4rem;
         font-weight: 600;
         color: $color-black;
         width: 12%;
         min-width: 110px;
         margin-right: 20px;
         p {
            margin: 15px 0;
         }
      }
      &_list {
         @include flex(center, flex-start);
         flex-wrap: wrap;
         width: 100%;
         font-size: 1.4rem;
      }
      &_checkbox {
         margin: 15px 15px;
         input {
            margin-right: 10px;
         }
      }
   }
}
.statistics {
   @include flex(center, space-between);
   width: 100%;
   margin-bottom: 35px;
   &__item {
      @include flex(center, center, column);
      width: 33%;
      height: 150px;
      position: relative;
      &::after {
         content: "";
         display: block;
         position: absolute;
         top: 0;
         right: 0;
         width: 1px;
         height: 100%;
         background-color: $color-light-grey;
      }
      &:last-child {
         &::after {
            content: none;
         }
      }

      p {
         font-size: 1.3rem;
         color: $color-grey;
      }
      h4 {
         font-size: 6.8rem;
         color: $color-black;
         line-height: 1;
      }
   }
}
