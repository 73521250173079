@import "../../styles/core/base";
@import "../../styles/modules/form";

.analytics {
   &__grid {
      display: grid;
      grid-template-columns: 1fr 1fr;
      column-gap: 24px;
   }
   &__content {
      margin: 20px 0;
      h2 {
         font-size: 2.4rem;
         font-weight: 600;
         color: $color-black;
         margin-bottom: 25px;
      }
   }
   &__header {
      @include flex(center, space-between);
      &_selects {
         @include flex(center, space-between);
         select {
            width: 200px;
            &:first-child {
               margin-right: 10px;
            }
         }
      }
   }
}
.yearChart {
   padding: 25px 25px 10px 25px;
   background: #ffffff;
   box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.05);
   border-radius: 6px;
   width: 100%;
   height: 265px;
   margin-bottom: 15px;
   h3 {
      font-size: 1.8rem;
      color: $color-black;
   }
   &__header {
      @include flex(center, space-between);
      &_select {
         width: 88px;
      }
   }
   &__tooltip {
      background: none !important;
      span {
         display: block;
         // margin: 5px;
         padding: 7px;
         border-radius: 4px;
         background-color: #0d2033;
         color: #fff;
         position: relative;
         line-height: 1;
      }
   }
   &__spinner {
      @include flex(center, center);
      > div {
         margin-top: 50px;
      }
   }
}
.doughnut {
   padding: 25px;
   background: #ffffff;
   box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.05);
   border-radius: 6px;
   width: 100%;
   margin-bottom: 15px;
   h3 {
      font-size: 1.8rem;
      color: $color-black;
   }
   & [class*="apexcharts-tooltip"] {
      background: none !important;
      box-shadow: none !important;
      border: none !important;
   }
   &__tooltip {
      background: none !important;
      span {
         display: block;
         margin: 5px;
         padding: 7px;
         border-radius: 4px;
         background-color: #0d2033;
         color: #fff;
         position: relative;
         &::after {
            content: "";
            display: block;
            position: absolute;
            bottom: -5px;
            left: 50%;
            margin-left: -5px;
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 5px 5px 0 5px;
            border-color: #0d2033 transparent transparent transparent;
         }
      }
   }
   &__data {
      @include flex(center, space-between);
      &_titles {
         p {
            padding-left: 20px;
            position: relative;
            font-size: 1.6rem;
            &:before {
               content: "";
               display: block;
               position: absolute;
               top: 50%;
               margin-top: -6px;
               left: 0;
               width: 10px;
               height: 10px;
               border-radius: 50%;
            }
            &:first-child {
               &:before {
                  background-color: $color-chart-green;
               }
            }
            &:last-child {
               &::before {
                  background-color: $color-chart-blue;
               }
            }
         }
      }
   }
   &__chart {
      width: 90px;
      height: 90px;
      overflow: hidden;
   }
}

.average {
   padding: 25px;
   background: #ffffff;
   box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.05);
   border-radius: 6px;
   width: 100%;
   margin-bottom: 15px;
   h3 {
      font-size: 1.8rem;
      color: $color-black;
   }
   &__info {
      @include flex(center, space-between);
      margin-top: 30px;
      p {
         font-size: 1.8rem;
         font-weight: 600;
         line-height: 1;
         span {
            font-size: 5.6rem;
         }
      }
      &_prev {
         padding: 10px;
         border: 1px solid #dddddd;
         box-sizing: border-box;
         border-radius: 4px;
         font-size: 1.8rem;
         font-weight: 600;
         svg {
            margin-right: 6px;
         }
      }
   }
}
