@import "../../styles/core/base";
@import "../../styles/modules/form";

.loginPage {
   width: 100%;
   height: 100%;
   position: relative;
   &__background {
      object-fit: cover;
      width: 100vw;
      height: 100vh;
      position: absolute;
      top: 0;
      left: 0;
   }
}
