@import "../../styles/core/base";

.header {
   @include flex(normal, space-between);
   background: $color-white;
   margin-bottom: 15px;
   position: fixed;
   width: calc(100% - 250px);
   right: 0;
   top: 0;
   z-index: 10;
   box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.07);

   &__btnGoBack {
      @include flex(center, center);
      width: 80px;
      border-right: 1px solid #dadada;
      button {
         width: 40px;
         height: 40px;
         transition: all 0.2s ease-out 0s !important;
         svg {
            transition: all 0.2s ease-out 0s !important;
            path {
               transition: all 0.2s ease-out 0s !important;
            }
         }
         &:hover {
            background: $color-main;
            svg {
               path {
                  fill: $color-white;
               }
            }
         }
      }
   }
   &__tooltip {
      position: relative;
      margin-left: 15px;
      &_btn {
         width: 45px;
         height: 45px;
         border: 1px solid $color-light-grey !important;
         background: none !important;
         border-radius: 4px !important;
         transition: all 0.2s ease-out 0s !important;
         svg {
            transition: all 0.2s ease-out 0s !important;

            path {
               transition: all 0.2s ease-out 0s !important;
            }
         }
         &:hover,
         &Active {
            border: 1px solid $color-main !important;
            background: rgba(5, 126, 238, 0.15) !important;
            svg {
               path {
                  fill: $color-main;
               }
            }
         }
      }
      &_content {
         position: absolute;
         top: calc(100% + 13px);
         right: 0;
         background: $color-white;
         padding: 10px 0;
         box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
         border-radius: 4px;
         min-width: 140px;
         &:before {
            content: "";
            display: block;
            position: absolute;
            top: 0;
            right: 0;
            width: 14px;
            height: 14px;
            box-sizing: border-box;
            border: 6px solid black;
            border-color: transparent transparent $color-white $color-white;
            transform-origin: 0 0;
            transform: rotate(135deg);
            box-shadow: -2px 2px 2px 0 rgba(61, 43, 43, 0.08);
         }
         &_btn {
            @include flex(center, flex-start);
            font-size: 1.4rem !important;
            width: 100%;
            height: 100%;
            background: none;
            padding: 7px 20px;
            margin-bottom: 7px;
            border: none !important;
            border-bottom: 1px solid $color-light-grey !important;
            svg {
               margin-right: 7px;
            }
            &:hover {
               background: rgba(5, 126, 238, 0.15) !important;
               color: $color-main;
            }
            &:last-child {
               border: none !important;
               margin-bottom: 0;
            }
         }
      }
   }
   &__right {
      @include flex(center, space-between);
      padding: 13px 25px;
      width: 100%;
      h3 {
         font-size: 1.8rem;
         color: $color-black;
      }
   }
   &__info {
      @include flex(center, space-between);
      p {
         margin: 5px 10px;
         font-weight: 600;
         span {
            font-weight: normal;
         }
      }
      &_status {
         width: 10px;
         height: 10px;
         border-radius: 50%;
         display: inline-block;
         &_Draft {
            background-color: $color-grey;
         }
         &_Published {
            background-color: $color-positive;
         }
         &_Unpublished {
            background-color: $color-red;
         }
      }
   }
   &__space {
      height: 60px;
   }
   &__schedule {
      @include flex(center, space-between);
      padding: 6px 12px;
      border-radius: 40px;
      color: $color-white;
      font-size: 1.4rem;
      min-width: 120px;
      margin-right: 15px;
      svg {
         margin-right: 10px;
      }

      &_red {
         background-color: $color-red;
      }
      &_green {
         background-color: $color-positive;
      }
   }
}
