@import "../../styles/core/base";
@import "../../styles/modules/form";

.fetching {
   @include flex(center, center);
   width: 100%;
}
.createContent {
   @include flex(center, center, column);
   &__field {
      @include flex(center, center);
      width: 190px;
      height: 250px;
      margin: 15px auto;
      border: 1px dashed $color-grey;
      box-sizing: border-box;
      filter: drop-shadow(0px 4px 14px rgba(0, 0, 0, 0.15));
      border-radius: 4px;
   }
   &__preview {
      width: 100%;
      overflow: auto;
      cursor: pointer;
      background: #ffffff;
      transition: all 0.2s;
      //&>main {
      //   width: 200% !important;
      //   height: 0 !important;
      //   padding-bottom: 100%;
      //   margin: -50%;
      //   transform: scale(.5)
      //}
      &:hover {
         box-shadow: 0 4px 14px rgba(0, 0, 0, 0.04);
      }
   }
   button {
      min-width: 190px !important;
      max-height: 100%;
   }
   &__editor {
      height: 100%;
      min-height: 100vh;
      width: 100%;
      min-width: 100%;
      padding-bottom: 15px;
      &_container {
         @include flex(center, space-between);
         width: 100%;
         max-width: 980px;
         margin: 0 auto;
      }
      &_header {
         background: $color-sidebar;
         padding: 15px;
         h3 {
            color: $color-white;
            font-size: 1.8rem;
         }
         img {
            width: 50px;
            height: 50px;
            margin-right: 15px;
         }
      }
      &_left {
         @include flex(center, flex-start);
      }
      &_right {
         @include flex(center, flex-start);
         button {
            min-width: 140px;
         }
      }
   }
}
.linkDialog {
   padding: 20px;
   h3 {
      font-size: 2rem;
      color: $color-black;
      margin-bottom: 10px;
   }
   p {
      line-height: 1.3;
      margin-bottom: 15px;
   }
   &__footer {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 10px;
      button {
         flex: 1 1 0;
         min-width: 30% !important;
         margin: 10px 0;
      }
   }
}
.settings {
   &__form {
      width: 100%;
   }
   &__btns {
      @include flex(center, flex-end);
      margin-top: 20px;
      button {
         min-width: 140px !important;
         &:last-child {
            margin-right: 0 !important;
         }
      }
   }
}
.campaignsEdit {
   padding-bottom: 20px;
   &__row {
      @include flex(normal, space-between);
      margin-bottom: 15px;
   }
   &__left {
      width: 35%;
      min-width: 370px;
   }
   &__right {
      width: 100%;
      margin-left: 15px;
   }
}
