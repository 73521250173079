@import "../../styles/core/base";
@import "../../styles/modules/form";

.editor {
   &__main {
      width: 100%;
      height: 100%;
      &_inner {
         @include flex(center, center);
         width: 100%;
         height: 100%;
      }
      &_spinner {
         width: 50px !important;
         height: 50px !important;
         border-width: 5px !important;
      }
   }
}
.createContent {
   @include flex(center, center);
   height: 100%;
   &__inner {
      @include flex(center, center, column);
   }

   &__field {
      @include flex(center, center);
      width: 190px;
      height: 250px;
      margin: 15px auto;
      border: 1px dashed $color-grey;
      box-sizing: border-box;
      filter: drop-shadow(0px 4px 14px rgba(0, 0, 0, 0.15));
      border-radius: 4px;

      &_img {
         border: none;
         overflow: hidden;
         img {
            width: 100%;
            height: 100%;
            object-fit: cover;
         }
      }
   }
   &__preview {
      width: 100%;
      overflow: auto;
      cursor: pointer;
      background: #ffffff;
      transition: all 0.2s;
      //&>main {
      //   width: 200% !important;
      //   height: 0 !important;
      //   padding-bottom: 100%;
      //   margin: -50%;
      //   transform: scale(.5)
      //}

      &:hover {
         box-shadow: 0 4px 14px rgba(0, 0, 0, 0.04);
      }
   }
   button {
      min-width: 190px !important;
      max-height: 100%;
   }
   &__editor {
      height: 100%;
      min-height: 100vh;
      width: 100%;
      min-width: 100%;
      padding-bottom: 15px;
      &_container {
         @include flex(center, space-between);
         width: 100%;
         max-width: 980px;
         margin: 0 auto;
      }
      &_header {
         background: $color-sidebar;
         padding: 15px;
         h3 {
            color: $color-white;
            font-size: 1.8rem;
         }
         img {
            width: 50px;
            height: 50px;
            margin-right: 15px;
         }
      }
      &_left {
         @include flex(center, flex-start);
      }
      &_right {
         @include flex(center, flex-start);
         button {
            min-width: 140px;
         }
      }
   }
}
.linkDialog {
   padding: 20px;
   h3 {
      font-size: 2rem;
      color: $color-black;
      margin-bottom: 10px;
   }
   p {
      line-height: 1.3;
      margin-bottom: 15px;
   }
   &__footer {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 10px;
      button {
         flex: 1 1 0;
         min-width: 30% !important;
         margin: 10px 0;
      }
   }
}
