@import "../../styles/core/base";

.userBlock {
   @include flex(center, space-between, column);
   height: 100%;
   background: $color-white;
   box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.05);
   border-radius: 6px;
   margin-bottom: 20px;
   &:last-child {
      margin-bottom: 0;
   }

   > h3 {
      @include flex(center, flex-start);
      width: 100%;
      padding: 25px 25px 20px 25px;
      font-size: 2rem;
      font-weight: 600;
      color: $color-black;
   }
}
