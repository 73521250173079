@import "../../styles/core/base";

.start {
   &__analytics {
      margin: 50px 0;
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 10px;
   }
}
.analyticBlock {
   @include flex(normal, space-between, column);
   background: #ffffff;
   padding: 24px;
   border-radius: 6px;
   box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.05);

   h3 {
      font-size: 1.3rem;
      font-weight: 600;
      color: $color-grey;
      margin-bottom: 15px;
   }
   p {
      font-size: 5rem;
      color: $color-sidebar;
      line-height: 1;
      font-weight: 600;
      span {
         font-weight: 400;
      }
   }
}
