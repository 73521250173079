@import "../../styles/core/base";
@import "../../styles/modules/form";

.createContent {
   @include flex(center, center, column);
   &__field {
      @include flex(center, center);
      width: 190px;
      height: 250px;
      margin: 15px auto;
      border: 1px dashed $color-grey;
      box-sizing: border-box;
      filter: drop-shadow(0px 4px 14px rgba(0, 0, 0, 0.15));
      border-radius: 4px;
   }
   button {
      min-width: 190px !important;
   }
   &__errorMessage {
      color: $color-red;
      text-align: center;
   }
}
