@import "../../styles/core/base";

.main {
   display: flex;
   width: 100%;
   //height: 100%;
   padding: 0;
   margin: 0;
   overflow-x: hidden;
   &__panel {
      min-height: 100vh;
      margin: 0 0 0 250px;
      padding: 45px 30px;
      display: inline-flex;
      flex-direction: column;
      flex-grow: 1;
      width: 100%;

      &_children {
         height: 100%;
      }
   }
}
