@import "../../styles/core/base";

.header {
   @include flex(center, space-between);
   margin-bottom: 15px;
   h1 {
      font-size: 2.4rem;
      color: $color-black;
   }
   p {
      font-size: 1.6rem;
      color: $color-black;
   }
   &__btns {
      @include flex(center, space-between);
   }
}
