@import "../../styles/core/base";
@import "../../styles/modules/form";

.campaigns {
   &__list {
      table {
         background: $color-white;
         box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.05);
         border-radius: 6px;
         font-size: 1.3rem;
         thead {
            border: none;
            box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.05);
            th {
               border: none;
            }
         }

         td,
         th {
            padding: 2.5rem;
         }
         tbody {
            tr {
               transition: all 0.5s;
               &:hover {
                  background: rgba(5, 126, 238, 0.15);
                  cursor: pointer;
               }
               &:last-child {
                  td {
                     border: none;
                  }
               }
            }
         }
      }
   }

   &__status {
      width: 11px;
      height: 11px;
      border-radius: 50%;
      display: inline-block;
      margin-right: 5px;
      &_Active {
         background: $color-positive;
      }
      &_Inactive {
         background: $color-grey;
      }
      &_Draft {
         background: $color-red;
      }
   }
}

.ownerSelected {
   &__item {
      @include flex(normal, space-between);
      background: $color-positive;
      color: $color-white;
      border-radius: 4px;
      border: 1px solid $color-dark-green;

      &_data {
         padding: 15px;
         text-align: center;
         p {
            font-size: 1.4rem;
         }
      }
   }
}
