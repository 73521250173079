@import "../../styles/core/base";

.editBlock {
   @include flex(center, space-between, column);
   height: 100%;
   padding: 25px;
   background: $color-white;
   box-shadow: 0 4px 14px rgba(0, 0, 0, 0.05);
   border-radius: 6px;
   &__flex_start {
      justify-content: flex-start !important;
   }

   h3 {
      @include flex(center, flex-start);
      width: 100%;
      font-size: 2rem;
      font-weight: 600;
      color: $color-black;
      margin-bottom: 20px;

      span {
         @include flex(center, center);
         width: 36px;
         height: 36px;
         border-radius: 50%;
         border: 1px solid $color-main;
         color: $color-main;
         margin-right: 10px;
      }
      svg {
         margin-left: 10px;
      }
   }
}
