@import "../../styles/core/base";

.select {
   width: 214px;
   background: rgba(5, 126, 238, 0.05);
   border: 1px solid $color-main;
   box-sizing: border-box;
   border-radius: 4px;
   position: relative;
   margin: 10px;
   &_ownershipSelect {
      width: auto;
   }

   &__inner {
      @include flex(normal, space-between);
      &_allActive {
         & [class*="item"] {
            &:first-child,
            &:last-child {
               background-color: $color-main;
               color: $color-white;
               svg {
                  path {
                     fill: $color-white !important;
                  }
               }
            }
            &:last-child {
               border-color: rgba($color: $color-white, $alpha: 0.4);
            }
         }
      }
      &_partiallyActive {
         & [class*="item"] {
            &:first-child {
               background-color: $color-main;
               color: $color-white;
            }
         }
      }

      &_title {
         padding: 15px;
         color: $color-main;
         width: 100%;
         cursor: pointer;
         &:hover {
            background: rgba(5, 126, 238, 0.1);
         }
      }
      &_btn {
         border-left: 1px solid $color-main;

         button {
            width: 50px !important;
            height: 100% !important;
            &:hover {
               background: rgba(5, 126, 238, 0.1);
               svg {
                  path {
                     fill: $color-main !important;
                  }
               }
            }
         }
      }
   }
   &__data {
      position: absolute;
      right: -1px;
      top: calc(100% + 13px);
      padding: 15px;
      background-color: $color-white;
      width: calc(100% + 2px);
      z-index: 100;
      box-shadow: 0px 1px 14px rgba(0, 0, 0, 0.15);
      border-radius: 2px;
      display: none;
      > div {
         margin-bottom: 10px;
         label {
            font-size: 1.3rem;
         }
         &:last-child {
            margin-bottom: 0;
         }
      }

      &:before {
         content: "";
         display: block;
         position: absolute;
         top: 0;
         right: 0;
         width: 14px;
         height: 14px;
         box-sizing: border-box;
         border: 6px solid black;
         border-color: transparent transparent $color-white $color-white;
         transform-origin: 0 0;
         transform: rotate(135deg);
         box-shadow: -2px 2px 2px 0 rgba(61, 43, 43, 0.08);
      }

      &_active {
         display: block;
      }
   }
}
