@import "../../styles/core/base";

.uploadFile {
   width: 100%;
   &__wrapper {
      @include flex(center, center, column);
      width: 100%;
      height: 200px;
      p {
         width: 100%;
         margin-bottom: 20px;
         button {
            text-transform: capitalize !important;
         }
      }
   }
   &__btns {
      @include flex(center, space-between);
      margin-top: 20px;
      button {
         min-width: 100px !important;
      }
   }
   &__input {
      opacity: 0;
      position: absolute;
      z-index: -1;
      overflow: hidden;
      width: 0.4px;
      height: 0.4px;
   }
   &__label {
      @include flex(center, center, column);
      width: 100%;
      height: 175px;
      border: 1px dashed $color-grey;
      color: $color-grey;
      font-size: 1.6rem;
      border-radius: 4px;
      cursor: pointer;
      svg {
         width: 66px;
         height: 66px;
      }
      &:hover {
         color: $color-main;
         border-color: $color-main;
         [class*="uploadFile__text"],
         svg {
            path {
               fill: $color-main;
            }
         }
      }
   }
   &__text {
      transition: 0.25s ease;
      font-size: 14px;
   }
   &__error {
      color: $color-red;
      margin-top: 15px;
      text-align: center;
   }
   &__preview {
      &_img {
         width: 150px;
         height: 150px;
         overflow: hidden;
         border-radius: 50%;
         border: 1px solid #ccc;
         background-color: #fff;
         img {
            width: 100%;
            height: 100%;
            border-radius: 50%;
            object-fit: cover;
         }
      }
   }
}

.fileImg {
   width: 100%;
   height: 200px;
   border-radius: 4px;
   filter: drop-shadow(0px 4px 14px rgba(0, 0, 0, 0.15));
   position: relative;

   &__close {
      position: absolute;
      top: 10px;
      right: 10px;
      background-color: $color-main !important;
      border-radius: 50% !important;
      width: 25px !important;
      height: 25px !important;
      &:hover {
         background: linear-gradient(0deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.1)), $color-main !important;
         svg {
            path {
               fill: $color-white !important;
            }
         }
      }
   }
   img {
      object-fit: cover;
      width: 100%;
      height: 100%;
      border-radius: 4px;
   }
}
