@import "../../styles/core/base";

.analytics {
   padding: 25px;
   background: $color-white;
   box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.05);
   border-radius: 6px;
   margin-bottom: 20px;

   h3 {
      color: $color-black;
      font-size: 2rem;
      margin-bottom: 20px;
      svg {
         vertical-align: inherit;
      }
   }
   &__block {
      @include flex(center, center);
      width: 100%;
   }

   &__item {
      @include flex(center, center, column);
      width: 20%;

      height: 100px;
      position: relative;
      &::after {
         content: "";
         display: block;
         position: absolute;
         top: 0;
         right: 0;
         width: 1px;
         height: 100%;
         background-color: $color-light-grey;
      }
      &:last-child {
         &::after {
            content: none;
         }
      }

      p {
         font-size: 1.3rem;
         color: $color-grey;
      }
      h4 {
         font-size: 4.8rem;
         color: $color-black;
         line-height: 1;
      }
   }
}
